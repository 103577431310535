@import "variables";

h1{
  font-size: $h1;
  line-height: 100%;
}
h2{
  font-size: $h2;
}
h3{
  font-size: $h3;
}
h4{
  font-size: $h4;
}
h5{
  font-size: $h5;
}