
@import "src/styles/variables";

.card {
  position: relative;
  width: 302px;
  height: 424px;
  border-radius: 8px;

  &.left{
    transform: rotate(-11.36deg);
    .cardBg2{
      left: -61px;
      top: -32px;
    }
    .cardFlag {
      right: -12px;
      top: 44px;
    }
  }
  &.right{
    transform: rotate(14.62deg);
    .cardBg2{
      width: 230.36px;
      left: 45.5px;
      top: -20px;
    }
    .cardFlag {
      left: -26.5px;
      bottom: 103.5px;
    }
  }




  &Bg1{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    &:before{
      content: '';
      position: absolute;
      width: 100.3%;
      height: 100.2%;
      border-radius: 8px;
      background: linear-gradient(180deg, rgba(23, 11, 113, 0.26) 0%, #170B71 100%);
    }
  }
  &Bg2{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: -1;
    background: $LeadColor_1;
  }

  &Flag {
    position: absolute;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(64px);
    border-radius: 16px;
    padding-left: 25px;
    padding-top: 16px;
    padding-bottom: 12px;
    z-index: 10;
    min-height: 71px;
    min-width: 171px;

    &Title {
      font-family: 'Anonymous Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;

      margin-bottom: 5px;
    }

    &SubTitle {
      font-family: 'Anonymous Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      opacity: 0.3;
      max-width: 100px;
    }
  }
}