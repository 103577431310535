
.launchpad {
  position: relative;
  min-height: 850px;

  &Main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
  }

  &Title {
    margin-top: 141px;
    margin-bottom: 4px;
  }

  &SubTitle {
    max-width: 370px;
    opacity: 0.6;
    line-height: 26px;
    text-align: center;
  }

  &Cards {
    margin-top: 52px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin-bottom: 40px;
      margin-right: 40px;
    }
    &>*:nth-child(3n){
      margin-right: 0;
    }

  }

  &TrLeft {
    position: absolute;
    z-index: -5;
    transform: rotate(-55.89deg);
    left: -284.72px;
    top: 298px;
  }

  &TrRight {
    position: absolute;
    z-index: -5;
    right: -163.35px;
    top: 63px;
  }
  &Big{
    position: absolute;
    z-index: -5;
    top: -250px;
    right: -800px;
  }


}