$fontFamily: "Farro", "Roboto Condensed", "Roboto", "Segoe UI", sans-serif;
$fontSize: 16;

//===============WIDTH====================

$minWidth: 375px;
$maxWidth: 1920;
$maxWidthContainer: 1062;
$md1: $maxWidthContainer + 12;
$md2: 991.98;
$md3: 767.98;
$md4: 479.98;

//===============COLORS====================
$mainColor:#fff;
$SecondaryTextColor:#CECFDE;

$bg_blue:#170B71;

$LeadColor_1: linear-gradient(270.9deg, #CE09FF -2.92%, #FFA34E 99.95%);
$LeadColor_2:linear-gradient(46.96deg, #CC16FF 20.56%, #48F573 82.71%);

$InfoBlockBackground:#2F1385;
$InputsBackground:#030B4D ;
$NavigationColor:#281981 ;
$MarketCelebity:linear-gradient(180deg, #24197E 0%, rgba(19, 10, 88, 0) 100%);

//===============FONT-SIZE====================
$h1:54px;
$h2:32px;
$h2_5:24px;
$h3:24px;
$h4:16px;
$h5:14px;
