@import 'src/styles/variables';

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 24px;
  z-index: 20;
  background: transparent;

  &Wrapper {
    padding: 16px 16px 16px 32px;
    border-radius: 8px;
    background: $LeadColor_1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.logoWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.search {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
}

.mobMenu {
  display: none;

  &Bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .logoWrapper,
  .wrapper {
    flex: initial;
  }
}

@media screen and (max-width: 992px) {
  .headerWrapper {
    padding: 11px 24px 11px 29px;
  }

  .navWrapper,
  .btnWrapper {
    display: none;
  }

  .search {
    margin: 0;
  }

  .mobMenu {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: $bg-blue;
    padding: 142px 62px 62px;
    display: flex;
    flex-direction: column;
    transition: all .5s;
    opacity: 0;
    
    &.active {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 375px) {
  .mobMenu {
    padding: 142px 52px 52px;
  }
}