
.launchpad {
  position: relative;
  min-height: 850px;

  &Main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &Title {
    max-width: 640px;
    text-align: center;
    margin: 365px 0 42px;
  }

  &SubTitle {
    line-height: 26px;
    text-align: center;
    opacity: 0.6;
    max-width: 470px;
    margin-bottom: 160px;
  }

  &Info {
    display: flex;
    width: 100%;
    min-height: 107px;
    background: rgba(63, 46, 193, 0.13);
    backdrop-filter: blur(74px);
    border-radius: 8px;
    padding: 31px 30px 31px 97px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      left:50%;
      transform: translate(-50%,0);
      width: 259px;
      height: 2px;
      background: linear-gradient(270.9deg, #CE09FF -2.92%, #FFA34E 99.95%);
      border-radius: 60px;
    }
    &>*:not(:last-child){
      margin-right: 44px;
    }
    &Block{
      display: flex;
      align-items: center;
      &Title{
        font-weight: 700;
        line-height: 104.4%;
        margin-right: 30px;
      }
      &SubTitle{
        font-family: 'Farro';
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        opacity: 0.4;
      }
    }



  }

  &CardLeft {
    position: absolute;
    z-index: -1;
    left: -27.21px;
    top: 342.58px;
  }

  &CardRight {
    position: absolute;
    z-index: -1;
    right: -23.62px;
    top: 298.25px;
  }

  &Gradient {
    position: absolute;
    z-index: -5;

    &Card {
      &Left {
        left: 102.79px;
        top: 186.58px;
        transform: rotate(-11.36deg);
      }

      &Right {
        top: 419.14px;
        right: 162.76px;
        transform: rotate(15.18deg);

      }
    }

    &Big {
      top: -500px;
      left: -600px;
    }

    &Plus {
      &Left {
        z-index: -3;
        left: 239px;
        top: 185px;
      }

      &Right {
        z-index: -3;
        right: 221px;
        top: 217px;
        transform: rotate(180deg);
      }
    }

  }
}