
.launchpad {
  position: relative;
  min-height: 850px;
  background: #130A58;

  &Main {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 5;
  }

  &Title {
    margin-top: 132px;
    margin-bottom: 16px;
  }

  &SubTitle {
    font-family: 'Roboto Condensed';
    max-width: 370px;
    opacity: 0.6;
    line-height: 26px;
    text-align: center;
  }

  &Cards {
    margin-top: 52px;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin-bottom: 40px;
      margin-right: 40px;
    }
    &>*:nth-child(3n){
      margin-right: 0;
    }

  }

  &TrLeft {
    position: absolute;
    transform: rotate(-55.89deg);
    left: -265.75px;
    top: 202px;
  }

  &Big{
    position: absolute;
    top: -350px;
    left: -1200px;
  }

  &Btn{
    position: absolute;
    top: 30px;
    left:50%;
    transform: translate(-50%,0);
    z-index: 10;
    &Con{
      position: relative;
      width: calc(100% - 33px);
      height: 219px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      &1{
        width: 327px;
        height: 384.8px;
        background: linear-gradient(180deg, #24197E 0%, rgba(19, 10, 88, 0) 100%);
        opacity: 0.2;
        border-radius: 8px;
      }
    }
  }

}