.container {
  display: inline-flex;
  position: relative;
  &Child{
    display: inline-flex;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
}