@import "variables";

* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
   outline: none;
}
a:focus,
a:active {
   outline: none;
}
aside,
nav,
footer,
header,
section {
  display: block;
}
html {
  font-size: #{$fontSize + px};
}
html,
body {
  min-height: 100vh;
  min-width: $minWidth;
  overflow-x: hidden;
}
body {
  line-height: 1;
  font-family: $fontFamily;
  //text-rendering: optimizeLegibility;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
input,
button,
textarea {
  font-family: $fontFamily;
  font-size: inherit;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
  background-color: inherit;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  color: antiquewhite;
  text-decoration: none;
  position: relative;
  line-height: 120%;
}
a:hover:before{
  transform: scale(1);
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}
//-webkit-overflow-scrolling: touch;
/*
&::-webkit-scrollbar {
	display: none;
}
*/
._container {
  max-width: $maxWidthContainer + px;
  margin: 0 auto;
  @media (max-width: $md1 + px) {
    max-width: 970px;
  }
  @media (max-width: $md2+px) {
    max-width: 750px;
  }
  @media (max-width: $md3+px) {
    max-width: none;
    padding: 0 10px;
  }
}
body{
  color:$mainColor;
  background: $bg_blue;
}