@import "../../../styles/variables";

.card {
  position: relative;
  width: 327px;
  min-height: 494px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #170D65;
  border-radius: 8px;

  &Img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;

    &:before {
      content: '';
      position: absolute;
      top: -.1px;
      left: -.1px;
      width: 100.1%;
      height: 101%;
      z-index: 1;
      background: linear-gradient(180deg, rgba(23, 13, 101, 0.49) 0%, #170D65 100%);
      border-radius: 8px 8px 0 0;
    }
  }

  &Main {
    position: relative;
    // flex: 1;
    z-index: 2;
    width: 100%;
    min-height: calc(494px - 48px);
    display: flex;
    flex-direction: column;

    & > * {
      flex: 1 50%;
    }
  }

  &Bg1 {
    min-height: 224px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &Btn {
      color: $mainColor;
      width: 100%;
      min-height: 32px;
      background: $LeadColor_1;
      backdrop-filter: blur(94px);
      border-radius: 4px;

      font-size: 14px;
      @media(hover: hover) {
        &:hover {
          background: $LeadColor_2;
        }
      }
    }

    &Title {
      padding-left: 8px;

      & > *:not(:last-child) {
        margin-bottom: 8px;
      }

      &Name {
        font-weight: 500;
        font-size: $h3;
        line-height: 104.4%;
        color: #FFFFFF;
      }

      &Type {
        line-height: 18px;
        opacity: 0.8;
        margin-bottom: 10px;
      }
    }

  }

  &Bg2 {
    &Before{
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: -24px;
        width: calc(100% + 41px);
        height: 1px;
        background: rgba(255, 255, 255, 0.06);
      }
    }
    &ProgressLine {
      margin-top: 33px;
      margin-bottom: 30px;
      height: 4px;
      width: 100%;
      background: #23158E;
      border-radius: 2px;
      overflow: hidden;

      &Active {
        height: 100%;
        background: $LeadColor_1;
        width: calc(100% - 30px);
        position: relative;

        &:before {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 44px;
          background: #23158E
        }

      }
    }
  }

}