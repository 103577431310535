.containerNews {
  font-family: 'Farro', sans-serif;
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 16px;
  max-width: 375px;

  p {
    font-family: 'Farro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 21px;
    height: 68px;
  }

  time {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 12px;
    opacity: 0.2;
  }

  &Btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 35px;
    min-width: 35px;
    width: 35px;
    height: 35px;
    margin-left: 25px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}