.link {
  font-weight: 500;
  margin-right: 48px;

  &:last-of-type {
    margin-right: 0;
  }
}

@media screen and (max-width: 992px) {
  .nav {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
  }

  .link {
    font-size: 20px;
    line-height: 20px;
    margin-right: 0;
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}