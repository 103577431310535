.card {
  &Bg2 {
    &Links {
      padding: 11px 8px 32px ;

      & > *:not(:last-child) {
        margin-right: 16px;
      }

    }
  }
}
