.hamburger {
  display: none;
}

@media screen and (max-width: 992px) {
  .hamburger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.active {
      .line {
        &:nth-child(1) {
          width: 19px;
          transform: rotate(45deg) translate(3px, 4px);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          width: 19px;
          transform: rotate(-45deg) translate(3px, -4px);
        }
      }
    }
  }

  .line {
    width: 14px;
    height: 1.5px;
    background: #fff;
    border-radius: 2px;
    transition: .3s;

    &:nth-child(2) {
      margin: 3.5px 0;
    }
  }
}