@import "src/styles/variables";

.footer {
  display: flex;
  justify-content: center;
  height: 459px;
  background-image: url("../../assets/bg/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 62px 24px 60px;


  &Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 490px;

    h4 {
      font-family: 'Farro', sans-serif;
      margin: 42px 0 32px;
      font-weight: 400;
      line-height: 26px;
      font-size: $h4;
      opacity: 0.41;
      text-align: center;
    }

    .social {
      display: flex;

      > li {
        margin-right: 24px;

        &:last-of-type {
          margin-right: 0px !important;
        }
      }
    }
  }
}


@media (max-width: 768px) {
  .footer {
    padding: 40px 24px 48px;
    background-image: url("../../assets/bg/footer_mobile_bg.png");

    &Container {

      h4 {
        font-family: 'Roboto Condensed', sans-serif;
        margin: 24px 0 80px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}

@media (max-width: 500px) {
  .footer{
    height: 440px;

    &Container {
      .logoFooter {
        width: 70px;
        height: 80px;
      }
    }
  }

}