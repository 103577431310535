.container {
  padding: 124px 24px 175px;
  display: flex;

  h2 {
    font-weight: 400;
    font-size: 54px;
    line-height: 54px;
    text-align: left;
  }

  .feedBack {
    width: 100%;
    font-family: 'Farro', sans-serif;

    > h2 {
      margin-bottom: 38px;
    }

    > p {
      font-family: 'Farro', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 48px;
      opacity: 0.4;
    }

    &Form {
      :global(.input) {
        margin-bottom: 40px;
      }

      :global(.button) {
        margin-top: 54px;
      }

      :global(.input__control) {
        height: 41px;
      }
    }
  }

  .news {
    width: 100%;
    margin-left: 161px;

    > div {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.figureLeft {
  position: absolute;
  bottom: 218px;
  left: 0;
  transform: rotate(-55.89deg) translateX(-379px);
  z-index: -1;
  opacity: 0.6;
  width: 625px;
  height: 559px;
}

.figureSecond {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(163px, -50%);
  opacity: 0.6;
  z-index: -1;
  width: 625px;
  height: 559px;
}

.figureFigure {
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  z-index: -1;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
}